.App {
  font-family: sans-serif;
  text-align: center;
}

.App .comment-avatar {
  background: #faa2c1;
  border-radius: 66px 67px 67px 0px;
  width: 2rem;
  height: 2rem;
  padding: 4px;
  margin: 4px;
}

.App .comment-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.button-wrapper button {
  z-index: 1;
  height: 40px;
  max-width: 200px;
  margin: 10px;
  padding: 5px;
}

.excalidraw .App-menu_top .buttonList {
  display: flex;
}

.excalidraw-wrapper {
  position: relative;
  overflow: hidden;
}

:root[dir="ltr"] .excalidraw .layer-ui__wrapper .zen-mode-transition.App-menu_bottom--transition-left {
  transform: none;
}

.excalidraw .panelColumn {
  text-align: left;
}

.export-wrapper {
  display: flex;
  flex-direction: column;
  margin: 50px;
}

.export-wrapper__checkbox {
  display: flex;
}

.excalidraw {
  --color-primary: #faa2c1;
  --color-primary-darker: #f783ac;
  --color-primary-darkest: #e64980;
  --color-primary-light: #fcc2d7;
}

.excalidraw button.custom-element {
  width: 2rem;
  height: 2rem;
  margin: 0 8px;
}

.excalidraw .custom-footer,
.excalidraw .custom-element {
  padding: 0.1rem;
}

.operations{
  float: right;
}