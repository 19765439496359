body {
  margin: 0;
  font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

div,
button {
  line-height: 132.19%;
}

/* block */

.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
  z-index: 1;
}

.error {
  color: red;
}

.container {
  height: 100%;
  width: 80%;
  margin: auto;
}

.col {
  display: flex;
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.wrap-flexitem {
  flex-wrap: wrap;
}

.flexrow {
  display: flex;
  flex-direction: row;
}

.logo {
  font-family: Merriweather;
}

/* height */

.h--30 {
  height: 30%;
}

.h--50 {
  height: 50%;
}

.h--100 {
  height: 100%;
}

.h--min--100 {
  min-height: 100%;
}

/* width */

.w--20 {
  width: 20%;
}

.w--30 {
  width: 30%;
}

.w--50 {
  width: 50%;
}

.w--100 {
  width: 100%;
}

.w--min--100 {
  min-width: 100%;
}

/* margin */

.mt--10 {
  margin-top: 10px;
}

.mb--10 {
  margin-bottom: 10px;
}

.ml--10 {
  margin-left: 10px;
}

.mr--10 {
  margin-right: 10px;
}

.mt--20 {
  margin-top: 20px;
}

.mb--20 {
  margin-bottom: 20px;
}

.ml--20 {
  margin-left: 20px;
}

.mr--20 {
  margin-right: 20px;
}

.mt--30 {
  margin-top: 30px;
}

.mb--30 {
  margin-bottom: 30px;
}

.ml--30 {
  margin-left: 30px;
}

.mr--30 {
  margin-right: 30px;
}

/* padding */

.p--10 {
  padding: 10px;
}

.pt--10 {
  padding-top: 10px;
}

.pb--10 {
  padding-bottom: 10px;
}

.pl--10 {
  padding-left: 10px;
}

.pr--10 {
  padding-right: 10px;
}

.pt--20 {
  padding-top: 20px;
}

.pb--20 {
  padding-bottom: 20px;
}

.pl--20 {
  padding-left: 20px;
}

.pr--20 {
  padding-right: 20px;
}

.pt--30 {
  padding-top: 30px;
}

.pb--30 {
  padding-bottom: 30px;
}

.pl--30 {
  padding-left: 30px;
}

.pr--30 {
  padding-right: 30px;
}

/* font */

.text--bold {
  font-weight: bold;
}

.text--12 {
  font-size: 12px;
}

.text--20 {
  font-size: 20px;
}

.text--30 {
  font-size: 30px;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--black {
  color: black;
}

.text--grey {
  color: #a4a4a4;
}

.text--white {
  color: white;
}

/* background */

.bg--purple {
  background: linear-gradient(180deg, #315cd5 0%, #315cd5 100%);
}

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
}

.react-tooltip-lite {
  background: #222;
  color: white;
  border-radius: 3px;
  font-size: 13px;
  max-width: 1000px !important;
}

.react-tooltip-lite-arrow {
  border-color: #222;
  margin-top: -1px;
}

.card-ih {
  background: #f9fafc;
  border-radius: 16px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.card-ih:hover {
  box-shadow: 0 16px 25px 0 rgba(0, 0, 0, 0.5);
}

.rotate-180 {
  animation: rotation 5s infinite linear;
}

@keyframes rotation {
  25% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.payment-btn,
.download-pdf-btn {
  margin: 0;
  border: none;
  padding: 8px 16px;
  background: #5b94e3;
  border-radius: 5px;
  font-size: 20px;
}

.gray {
  color: #6c757d;
}

.default-btn {
  margin: 0;
  padding: 8px 16px;
  background: #fff;
  border-radius: 5px;
  font-size: 20px;
  color: #5b94e3;
  border-color: #5b94e3;
  border-style: solid;
}

.modalHeaderStyle {
  border-bottom: solid 2px efefef;
  padding: 20px;
  color: #5b94e3;
}

/* Styling auto complete of matirial ui */
.MuiAutocomplete-listbox {
  border: 0.5px solid #3F51B5;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option:hover {
  background: #E3E1E1 !important;
}

.input-element,
.input-element-select {
  box-shadow: none;
  padding: 0 0.5rem;
  border-radius: 3px;
  width: 100%;
  box-sizing: border-box;
  border: unset;
}

.input-element {
  outline: 1px solid #bfd1ff;
}

.input-element.Mui-focused,
.input-element:focus {
  outline-width: 2px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #bfd1ff !important;
}

.individual-radio-btn {
  margin-left: -11px
}

.input-element-select {
  outline: 0x solid #bfd1ff !important;
}

.input-element-select.Mui-focused {
  outline-width: 1px;
}

.MuiSelect-select {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}