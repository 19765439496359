#shared-answer-container .excalidraw-wrapper {
    height: 400px !important;
    width: 100%;
}

#shared-answer-container {
    width: 100%;
}

.chip-xs {
    height: 20px !important;
    font-size: 10px !important;
}

#answer-container .record-pane {
    display: none;
}

#answer-container .record-pane.visible {
    display: block;
}

