.App {
  text-align: center;
  height: 100%;
}

.ql-snow .ql-tooltip {
  left: 0 !important;
}

.modal-content {
  /* width: 115% !important; */
}

.candidate-profile-modal-content {
  width: 80% !important;
  overflow: hidden;
}

.candidate-profile-modal-container {
  display: flex;
  justify-content: center;
  height: max-content;
}

.add-task-modal .modal-content {
  width: 125% !important;
  min-width: 1200px !important;
  height: 332px !important;
}

.add-task-modal .modal-dialog-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-task-modal .modal-dialog {
  height: auto !important;
}

/* div.ag-theme-alpine div.ag-row {
  font-weight: bold;
} */

.reactleaving-modal .modal-content {
  margin: 0px auto !important;
  width: 589px !important;
}

.reactleaving-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 92vh !important;
}

.Fontsize {
  font-weight: bold;
}

.textalign {
  text-align: right !important;
}
