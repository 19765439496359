.result-label {
    font-size: .9rem;
    font-weight: 600!important;
    color: #4a4a4a;
}

.disable-editor {
    pointer-events: none;
    opacity: 0.8;
}

.ace_print-margin {
    display: none;
}

.text-xs {
    font-size: 12px;
}

.bg-black {
    background-color: black;
}

.cursor-pointer {
    cursor: pointer;
}

.hw-12 {
    height: 12px !important;
    width: 12px !important;
}

#terminal-editor .ace_active-line {
    background-color: black !important;
}